<template>
  <div class="mt-5 pt-2">
    <div class="card" :class="$style.container">
      <div class="mb-4">
        Change your password
        <br />
      </div>
      <!-- <div class="mb-4">
        <a-radio-group
          :value="settings.authProvider"
          @change="e => changeAuthProvider(e.target.value)"
        >
          <a-radio value="jwt">JWT</a-radio>
          <a-radio value="firebase">Firebase</a-radio>
        </a-radio-group>
      </div> -->
      <a-form
        :model="loginForm"
        :rules="rules"
        layout="vertical"
        class="mb-4"
        @finish="handleFinish"
        @finishFailed="handleFinishFailed"
      >
        <div class="relative">
          <span class="error" v-if="lengthError"> Password must be longer than 6 characters </span>

          <a-form-item class="relative" name="password">
            <a-input v-model:value="loginForm.password" placeholder="Password" type="password" />
          </a-form-item>
        </div>
        <div class="relative">
          <span class="error" v-if="confirmError"> Password does not match </span>
          <a-form-item class="relative" name="password">
            <a-input
              v-model:value="loginForm.confirm"
              placeholder="Confirm Password"
              type="password"
            />
          </a-form-item>
        </div>
        <a-button
          type="primary"
          html-type="submit"
          class="text-center w-100"
          :loading="loading"
          @click="changePassword"
        >
          <strong>Change password</strong>
        </a-button>
      </a-form>
    </div>
  </div>
</template>
<script>
import { useRouter, useRoute } from 'vue-router'
import axios from '@/axios'
import Swal from 'sweetalert2/dist/sweetalert2.js'

export default {
  data() {
    return {
      router: useRouter(),
      route: useRoute(),
      loginForm: {
        password: '',
        confirm: '',
      },
      loading: false,
    }
  },
  computed: {
    lengthError() {
      return this.loginForm.password && this.loginForm.password.length < 7
    },
    confirmError() {
      return (
        this.loginForm.confirm.length > 2 &&
        this.loginForm.password.length > 2 &&
        this.loginForm.confirm !== this.loginForm.password
      )
    },
  },
  methods: {
    changePassword() {
      this.loading = true
      if (this.confirmError || this.lengthError) {
        this.loading = false
        return
      } else {
        axios
          .post('/auth/update_password', {
            change_password_token: this.route.query['change-password-token'],
            password: this.loginForm.password,
          })
          .then((res) => {
            this.loading = false
            Swal.fire({
              title: 'Success!',
              text: 'Password changed',
              icon: 'success',
              confirmButtonText: 'Ok',
            }).then((result) => {
              if (result.value) {
                this.router.push('/auth/login')
              }
            })
          })
          .catch((err) => {
            this.loading = false
            const error = err.response.data.message
            let messages
            if (Array.isArray(error)) messages = [...err.response.data.message]
            else messages = err.response.data.message
            Swal.fire({
              title: 'Error!',
              text: messages,
              icon: 'error',
              confirmButtonText: 'Ok',
            })
          })
      }
    },
  },
}
</script>
<style lang="scss" module>
@import '@/@vb/components/Auth/style.module.scss';
</style>
<style scoped>
span.error {
  right: 0;
  top: -36px;
}
</style>
