import { render } from "./change-password.vue?vue&type=template&id=e9bd35a8&scoped=true"
import script from "./change-password.vue?vue&type=script&lang=js"
export * from "./change-password.vue?vue&type=script&lang=js"

const cssModules = script.__cssModules = {}
import style0 from "./change-password.vue?vue&type=style&index=0&id=e9bd35a8&lang=scss&module=true"
cssModules["$style"] = style0
import "./change-password.vue?vue&type=style&index=1&id=e9bd35a8&scoped=true&lang=css"
script.render = render
script.__scopeId = "data-v-e9bd35a8"

export default script